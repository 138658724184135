import css from "./MobileMenu.module.css";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ isOpen, onClose }) => {
  return (
    <div
      className={clsx(css.mobileMenuContainer, {
        [css.open]: isOpen,
        [css.closed]: !isOpen,
      })}
    >
      <NavLink
        className={({ isActive }) =>
          clsx(css.mobileMenuLink, { [css.activeLink]: isActive })
        }
        to="/"
        onClick={onClose}
      >
        Home
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          clsx(css.mobileMenuLink, { [css.activeLink]: isActive })
        }
        to="/about"
        onClick={onClose}
      >
        About Us
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          clsx(css.mobileMenuLink, { [css.activeLink]: isActive })
        }
        to="/services"
        onClick={onClose}
      >
        Services
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          clsx(css.mobileMenuLink, { [css.activeLink]: isActive })
        }
        to="/projects"
        onClick={onClose}
      >
        Projects
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          clsx(css.mobileMenuLink, { [css.activeLink]: isActive })
        }
        to="/contact"
        onClick={onClose}
      >
        Contact Us
      </NavLink>
    </div>
  );
};

export default MobileMenu;
