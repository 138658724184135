import "./App.css";
import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const MainPage = lazy(() => import("./pages/MainPage/MainPage"));
const WhoWeArePage = lazy(() => import("./pages/WhoWeArePage/WhoWeArePage"));
const ServicePage = lazy(() => import("./pages/ServicePage/ServicePage"));
const ProjectsPage = lazy(() => import("./pages/ProjectsPage/ProjectsPage"));
// const OurClientsPage = lazy(() =>
//   import("./pages/OurClientsPage/OurClientsPage")
// );
const ContactPage = lazy(() => import("./pages/ContactPage/ContactPage"));
const PrivacyPolicyPage = lazy(() =>
  import("./pages/PrivacyPolicyPage/PrivacyPolicyPage")
);
const NotFoundPage = lazy(() => import("./pages/NotFoundPage/NotFoundPage"));

function App() {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<WhoWeArePage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          {/* <Route path="/clients" element={<OurClientsPage />} /> */}
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
