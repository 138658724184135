import css from "./Loader.module.css";
import sprite from "../../public/assets/icons.svg";

const Loader = () => {
  return (
    <div className={css.loaderContainer}>
      <svg className={css.loader}>
        <use href={`${sprite}#${"icon-x"}`}></use>
      </svg>
    </div>
  );
};

export default Loader;
